import {
  Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from "react";
  
 
  
  function TableLoader({ type, columns = 5, rows = 8 }) {
    const LOADER_TYPES = {
      table: "table",
    };
    const renderLoader = {
      [LOADER_TYPES.table]() {
        return (
            <>
           <Box>
           <Typography textAlign={"center"} sx={{color:"#7D828F"}}>Fetching Your Data</Typography>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {[...Array(columns)].map((col,index) => (
                  <TableCell key={index}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            
              {[...Array(rows).keys()].map((row,index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {[...Array(columns).keys()].map((item,index) => (
                    <TableCell key={index}>
                        
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
           </Box>
            </>
        );
      },
    };
  
    return renderLoader[type]();
  }
  
  export default TableLoader;
  