import { Box, Button } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import axios from "axios";
import React, { memo, useEffect, useRef, useState } from "react";
import TableLoader from "../common/TableLoader";

import RefreshIcon from "@mui/icons-material/Refresh";
import "../../assets/css/statistics.css";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { SERVER_URL } from "../../config";

// custom toolbar with custom default filters
const CustomToolbar = ({ fetchList }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
      />
      <Box>
        <Button startIcon={<RefreshIcon />} onClick={fetchList}>
          Refresh
        </Button>
      </Box>
    </GridToolbarContainer>
  );
};

function Statistics({
  rows,
  setRows,
  isFetchingTableData,
  setIsFetchingTableData,
}) {
  const [tempRows, setTempRows] = useState([]);
  // const [mcatString,setMcatString]=useState("");
  // const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const tempRowsRef = useRef(tempRows); // Use a ref to track tempRows

  useEffect(() => {
    tempRowsRef.current = tempRows; // Update the ref every time tempRows changes
  }, [tempRows]);

  const columns = [
    {
      field: "id",
      headerName: "Sr No.",
      width: 90,
      textAlign: "center",
      align: "center",
    },
    {
      field: "MCAT Name",
      headerName: "MCAT Name",
      width: 250,
      textAlign: "center",
    },
    {
      field: "Total Images (Overall)",
      headerName: "Total Images (Overall)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
    },
    {
      field: "Total Anomalies (Overall)",
      headerName: "Total Anomalies (Overall)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
    },
    {
      field: "Anomaly Percentage (Overall)",
      headerName: "Anomaly Percentage (Overall)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
    },
    {
      field: "Images (Premium Sellers)",
      headerName: "Images (Premium Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomalies (Premium Sellers)",
      headerName: "Anomalies (Premium Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomaly Percentage (Premium Sellers)",
      headerName: "Anomaly Percentage (Premium Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Premium Sellers",
      headerName: "Premium Sellers",
      type: "string",
      width: 200,
      textAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "Images (Paid Sellers)",
      headerName: "Images (Paid Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },

    {
      field: "Anomalies (Paid Sellers)",
      headerName: "Anomalies (Paid Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomaly Percentage (Paid Sellers)",
      headerName: "Anomaly Percentage (Paid Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Paid Sellers",
      headerName: "Paid Sellers",
      type: "string",
      width: 200,
      textAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "Images (Free-verified Sellers)",
      headerName: "Images (Free-verified Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomalies (Free-verified Sellers)",
      headerName: "Anomalies (Free-verified Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomaly Percentage (Free-verified Sellers)",
      headerName: "Anomaly Percentage (Free-verified Sellers)",
      type: "number",
      width: 250,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Free-verified Sellers",
      headerName: "Free-verified Sellers",
      type: "string",
      width: 250,
      textAlign: "center",
      align: "center",
      filterable: false,
    },
   
    {
      field: "Images (Free Sellers)",
      headerName: "Images (Free Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },

    {
      field: "Anomalies (Free Sellers)",
      headerName: "Anomalies (Free Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Anomaly Percentage (Free Sellers)",
      headerName: "Anomaly Percentage (Free Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },

    {
      field: "Free Sellers",
      headerName: "Free Sellers",
      type: "string",
      width: 200,
      textAlign: "center",
      align: "center",
      filterable: false,
    },

    {
      field: "Images (Unknown Sellers)",
      headerName: "Images (Unknown Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },

    {
      field: "Anomalies (Unknown Sellers)",
      headerName: "Anomalies (Unknown Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },

    {
      field: "Anomaly Percentage (Unknown Sellers)",
      headerName: "Anomaly Percentage (Unknown Sellers)",
      type: "number",
      width: 200,
      textAlign: "center",
      align: "center",
      hideable: true,
    },
    {
      field: "Unknown Sellers",
      headerName: "Unknown Sellers",
      type: "string",
      width: 200,
      textAlign: "center",
      align: "center",
      filterable: false,
    },
  ];

  const fetchList = async () => {
    setRows([]);
    setIsFetchingTableData(true);
    try {
      const response = await axios.get(`${SERVER_URL}/mcats_stats/`);

      //   setRows(response.data.csv_stats);
      //   console.log("response", response);
      const fetchedRows = response.data.map((item, index) => ({
        id: index + 1, // Fallback to index if no unique ID available
        ...item,
      }));
      setRows(fetchedRows);

      setIsFetchingTableData(false);
      setTempRows(fetchedRows);
    } catch (error) {
      //   console.log("error is", error);
      setIsFetchingTableData(false);
    }
  };

  /* function to search in the table
  const searchInTable=(searchString) => {

    setMcatString(searchString);

    if(searchString==="")
   {
    setRows(tempRows);
    return;
   }

    setRows(tempRows.filter((row)=>row.mcat.includes(searchString)));
  }
  */

  useEffect(() => {
    if (!isFetchingTableData && rows.length === 0) fetchList();
    else if (rows.length !== 0) {
      setTempRows(rows);
    }

    return () => {
      if (tempRowsRef.current.length > 0) {
        setRows(tempRowsRef.current);
      }
    };
  }, []);

  return (
    <Box sx={{ height: 680, width: "100%" }} px={1}>
      {isFetchingTableData ? (
        <TableLoader type={"table"} />
      ) : (
        <>
          {/* <Box textAlign={"right"} mb={1}>
            <Button
              variant="contained"
              size="small"
              onClick={fetchList}
              startIcon={<RefreshIcon />}
              sx={{ height: 35 }}
            >
              Refresh
            </Button>
          </Box> */}
          {/* <Box  mb={1}
          
          >
            <input type="text"
           placeholder="Search Mcat"
           className="table-searchbar"
           value={mcatString}
            onChange={(e)=>searchInTable(e.target.value)}
           />
          </Box> */}
          <DataGridPro
            rows={rows}
            columns={columns}
            pagination
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  "Anomaly Percentage (Free-verified Sellers)": false,
                  "Anomaly Percentage (Free Sellers)": false,
                  "Anomaly Percentage (Premium Sellers)": false,
                  "Anomaly Percentage (Paid Sellers)": false,
                  "Images (Free-verified Sellers)": false,
                  "Anomalies (Free-verified Sellers)": false,
                  "Images (Free Sellers)": false,
                  "Anomalies (Free Sellers)": false,
                  "Images (Unknown Sellers)": false,
                  "Anomalies (Unknown Sellers)": false,
                  "Anomaly Percentage (Unknown Sellers)": false,
                  "Images (Premium Sellers)": false,
                  "Anomalies (Premium Sellers)": false,
                  "Images (Paid Sellers)": false,
                  "Anomalies (Paid Sellers)": false,
                  "Unknown Sellers": false,

                },
              },

              pagination: {
                paginationModel: {
                  pageSize: 30,
                },
              },
            }}
            pageSizeOptions={[20, 30, 40]}
            slots={{
              toolbar: (props) => (
                <CustomToolbar {...props} fetchList={fetchList} />
              ),
            }}
            disableRowSelectionOnClick
            sx={{
              fontSize: "1rem",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
                fontWeight: "550", // Force font weight to bold
                fontSize: "0.9rem",
                mb: 1,
                textAlign: "center",
              },
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
                textAlign: "center",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "175px !important",
                textAlign: "center",
              },
            }}
          />
        </>
      )}
    </Box>
  );
}

export default memo(Statistics);
