import { Card, CardMedia, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";


function LazyImageComp({ src, alt, display_id }) {
    const imgRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    
    // console.log('src is',src);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
             
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 }
      );
  
      if (imgRef.current) {
        observer.observe(imgRef.current);
      }
  
      return () => {
        if (imgRef.current) {
          observer.disconnect();
        }
      };
    }, []);
  
    return (
     <a href={src} target="_blank" sx={{textDecoration:"none"}}>
      <Card sx={{ maxWidth: 500 }}>
        <CardMedia
          ref={imgRef}
          sx={{ height: 300,
            transition: 'opacity 0.5s ease', // optional, for smooth transition
            '&:hover': {
              opacity: 0.5, // change this value to whatever you want on hover
            },
           }}
          image={isVisible ? src : undefined}
          title={alt}
          
        />
        <Typography variant="body1" my={1} fontSize={"bold"}>
          Display ID: {display_id}
        </Typography>
      </Card>
     </a>
    );
  }

  export default memo(LazyImageComp);