import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import Layout from "../Layout/layout";
import About from "../pages/about";

 

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <Layout />
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      
    ],
  },
]);