import { LicenseInfo } from "@mui/x-license";
import React, { useEffect } from "react";
 
import { Outlet } from "react-router-dom";

function Layout() {

    useEffect(() => {
        
        const MUI_KEY= process.env.REACT_APP_MUI_KEY;
    
        LicenseInfo.setLicenseKey(MUI_KEY);
      }, [window.location.pathname]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default Layout;